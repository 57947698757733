<template>
  <div class="pay">
    <div class="back">
      <img
        src="https://oss.boxuanedu.cn/boxuanedu-m/lesson/pay/icon1.png"
        alt="icon"
        class="icon"
        @click="backtolessoninfo()"
      />
      <p class="title">确认订单</p>
    </div>

    <div class="timecount">
      <img src="https://oss.boxuanedu.cn/boxuanedu-m/lesson/pay/bg2.jpg" alt="bg" class="bg" />
      <p>博轩好课 限时抢购</p>
    </div>

    <div class="choseinfo">
      <div class="banner">
        <p class="left">当前选择</p>
        <p class="right" @click="backhome">重新选择</p>
      </div>

      <div class="banner">  
        <p class="title">{{ thelessoninfo.lessontitle }}</p>
        <p class="price">￥{{ thelessoninfo.price }}</p>
      </div>
    </div>

    <div class="tel">
      <div class="top">
        <p class="commonp">学习账号</p>
        <img class="icon" src="https://oss.boxuanedu.cn/boxuanedu-m/lesson/pay/icon3.png" alt="icon" />
        <p class="grayp">已加密</p>
      </div>

      <div class="telinput">
        <p class="des">手机</p>
        <p class="des">+86</p>
        <div class="line"></div>
        <!-- <input type="number" name="" class="input" value="输入你的手机号码" max="11"> -->
        <van-field
          v-model="tel"
          type="number"
          maxlength="11"
          placeholder="请输入手机号码"
        />
      </div>
    </div>

    <div class="payway">
      <p class="common">支付方式</p>

      <van-radio-group v-model="payway">
        <van-cell-group>
          <van-cell
            name="1"
            icon="wechat"
            title="微信支付"
            clickable
            @click="payway = '1'"
          >
            <van-radio name="1" checked-color="#07c160" />
          </van-cell>
          <!-- <van-cell
              icon="alipay"
              title="支付宝"
              clickable
              @click="payway = '2'"
            >
              <van-radio name="2" checked-color="#07c160" />
            </van-cell> -->
        </van-cell-group>
      </van-radio-group>
    </div>

    <div class="bottompay">
      <div class="p">
        限时特惠111:
        <p class="num">{{ smallprice }}{{ thelessoninfo.price }}</p>
      </div>
      <!-- <div  @click="surepay">确认支付</div> -->
      <van-button class="btn" :disabled="canpay" round @click="surepay"
        >去付款</van-button
      >
    </div>
  </div>
</template>

<script>
import { Field, CellGroup, Cell, RadioGroup, Radio, Button } from "vant";
import { bxlessons } from "@/tempdata/lesson/lessons";
import axios from "axios";
export default {
  components: {
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
  },

  data() {
    return {
      tel: "",
      payway: "1",
      code: "",
      thelessoninfo: "",
      /* 简易防抖 */
      surepaylong: 1,
    };
  },
  computed: {
    // button按钮可触发
    canpay() {
      if (this.tel.length == 11) {
        return false;
      } else {
        return true;
      }
    },
    smallprice() {
      if (this.thelessoninfo.price <= 10) {
        const end = Number(this.thelessoninfo.price).toFixed(2);
        this.thelessoninfo.price = end;
      }
    },
  },

  methods: {
    /* url解析 */
    setleesoninfo() {
      this.code = this.$route.query.code;
      for (const item of bxlessons) {
        if (this.code == item.paycode) {
          this.thelessoninfo = item;
          return;
        }
      }
    },

    /* 跳转到对应课程详情 */
    backtolessoninfo() {
      if (this.code == "EDDn") {
        this.$router.push({
          name: "lessoninfotwo",
          query: { code: this.code },
        });
      } else {
        this.$router.push({
          name: "lessoninfo",
          query: { code: this.code },
        });
      }
    },
    /*跳转到首页重新选择*/
    backhome() {
      this.$router.push({ name: "Home" });
    },
    /* 判断是否是微信内置浏览器 */
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf("micromessenger") > -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },
    /* 确认支付 */
    surepay() {
      const tel = this.tel;
      const paycode = this.code;
      //   const anchor = this.$route.query.anchor;
      const iswx = this.isWeiXin();
      if (this.surepaylong) {
        
        // 微信支付
        if (this.payway == "1") {
          
          // 是微信内置浏览器
          if (iswx) {
            const appId = "wx1b1406bfba139237";
            const redirect_uri = "s.boxuanedu.cn";
            const state = `${tel}_${paycode}`;
            const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=https://${redirect_uri}/dd/rest/wepay/logincode&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;
            window.location.href = url;
          } else {
            axios({
              method: "get",
              url: `https://m.boxuanedu.cn/dd/rest/wepay/payWapH5?pcode=${paycode}&phoneNum=${tel}&payType=2`,
            }).then((res) => {
              console.log(res);
              const url = res.data.h5_url;
              window.location.href = url;
            });
          }
        }else{
          // window.open(
          //   `https://api.boxuanedu.cn/dd/rest/alipay/goAlipay?pcode=${paycode}&phoneNum=${tel}&payType=1`
          // );
        }

        this.surepaylong = 0;
        setTimeout(() => {
          this.surepaylong = 1;
        }, 3000);
      }
    },
  },
  created() {
    this.setleesoninfo();
  },
  mounted() {
    // console.log(window.location.href.indexOf("state"));
    if (window.location.href.indexOf("state") != -1) {
      const theurl = window.location.href;
      let aim = theurl.slice(-16);
      const tel = aim.split("_")[0];
      const paycode = aim.split("_")[1];

      axios({
        method: "get",
        url: `https://m.boxuanedu.cn/dd/rest/wepay/payWechatH5?pcode=${paycode}&phoneNum=${tel}`,

        // url: `https://das.lttx666.cn/dd/rest/wepay/payWechatH5?pcode=nEw2&phoneNum=3333`,
      }).then((res) => {
        const { appId, timeStamp, nonceStr, paySign, prepay_id, orderNum } =
          res.data;

        console.log(paySign);
        // wx内置接口
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: "wx1b1406bfba139237", // 公众号ID，由商户传入
            timeStamp, // 时间戳，自1970年以来的秒数?
            nonceStr, // 随机串
            package: `prepay_id=${prepay_id}`,
            signType: "RSA", // 微信签名方式：
            paySign: paySign, // 微信签名
            orderNum,
          },
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              //   window.location.href = "https://t.nanfangjiankang.com/20Ir0";
              // window.location.href = "https://t.boxuanedu.cn/OzFZ3";
              //  https://m.boxuanedu.cn/dd/rest/wepay/getUrl/{orderNum}

              window.location.href = `https://m.boxuanedu.cn/dd/rest/wepay/getUrl/${orderNum}`;
            }
            window.location.href = `https://m.boxuanedu.cn/dd/rest/wepay/getUrl/${orderNum}`;
          }
        );
      });
    }
  },
};
</script>

<style lang="less" scoped>
.pay {
  width: 100vw;
  height: 100vh;
  background: #f5f8ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .back {
    width: 100%;
    height: 50px;
    padding-left: 11px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    .icon {
      width: 24px;
      height: 24px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #e6e6e6;
      border-radius: 50%;
      margin-right: 9px;
    }
  }
  .timecount {
    width: 100%;
    padding: 15px 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .bg {
      width: 100%;
      object-fit: contain;
    }
    p {
      position: absolute;
      font-size: 28px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #066cff;
    }
  }
  .choseinfo {
    width: calc(100vw - 20px);
    background: #fff;
    padding: 15px 11px 16px 11px;
    box-shadow: 0px 3px 6px 1px rgba(6, 108, 255, 0.08);
    margin-bottom: 19px;
    .banner {
      display: flex;
      justify-content: space-between;
      .left {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 14px;
      }
      .right {
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #066cff;
      }
      .title {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
      }
      .price {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  .tel {
    width: 354px;
    height: 83px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(6, 108, 255, 0.08);
    border-radius: 4px 4px 4px 4px;
    padding: 15px 11px 16px 11px;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .commonp {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }

      .grayp {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
      }
      .icon {
        width: 9px;
        object-fit: contain;
        margin-right: 5px;
        margin-left: 10px;
      }
    }
    .telinput {
      width: 100%;
      display: flex;
      align-items: center;
      .des {
        width: 32px !important;
        font-size: 15px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
        margin-right: 11px;
      }
      .line {
        width: 0px;
        height: 17px;
        opacity: 1;
        border: 1px solid #9b9b9b;
      }
      .input {
        border: none;
        margin-left: 6px;
      }
    }
  }

  .payway {
    width: 354px;
    margin-top: 14px;
    padding: 12px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(6, 108, 255, 0.08);
    border-radius: 4px 4px 4px 4px;
    .common {
      margin-bottom: 10px;
    }
    .van-radio {
      display: flex;
      align-items: center;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      position: absolute;
      right: 0;
    }
    .van-cell {
      width: 100%;
      padding: 0;
    }
  }

  .bottompay {
    width: 100%;
    padding: 10px 5px;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    display: flex;
    border-radius: 8px 8px 0 0;
    align-items: flex-end;
    justify-content: space-between;

    .p {
      display: flex;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      align-items: baseline;
      .num {
        font-size: 38px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #066cff;
      }
    }

    .btn {
      width: 158px;
      height: 51px;
      background: linear-gradient(180deg, #066cff 0%, #217bfe 100%);
      border-radius: 26px 26px 26px 26px;
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 60%;
  padding: 0;
  overflow: hidden;
  color: #323233;
  font-size: 3.733333vw;
  line-height: 6.4vw;
  background-color: #fff;
  padding-left: 12px;
}

.van-radio-group {
}

.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border-width: 0;
}
</style>

<template>
  <div>
    <theheader></theheader>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img
          class="topimg"
          src="https://oss.boxuanedu.cn/boxuanedu-m/teacher/bg1"
          alt="img"
        />
      </van-swipe-item>
      <van-swipe-item>
        <img
          class="topimg"
          src="https://oss.boxuanedu.cn/boxuanedu-m/teacher/bg2"
          alt="img"
        />
      </van-swipe-item>
    </van-swipe>

    <div class="teacher">
      <div class="pagedes">
        <p class="pagep">对教育怀揣敬畏之心 用爱成就每一位学生</p>
        <img
          class="desimg"
          src="https://oss.boxuanedu.cn/boxuanedu/lesson/desimg"
          alt=""
        />
        <p class="desinfo">
          10余年来，博轩始终秉承“让每个人都能公平和便利地获取优质教育服务，并实现可持续成长”的使命，以高水平教学质量和优质服务，赢得了用户的信任与尊重。
        </p>
      </div>

      <div class="tline"></div>

      <div class="btnbox">
        <div :class="chose1 ? 'btn btnchose' : 'btn'" @click="chosebtnbox(0)">
          <p class="num">80+</p>
          <p class="des">专职课研团队</p>

          <div v-if="chose1" class="btnflag"></div>
        </div>

        <div :class="chose2 ? 'btn btnchose' : 'btn'" @click="chosebtnbox(1)">
          <p class="num">800+</p>
          <p class="des">专职教学团队</p>
          <div v-if="chose2" class="btnflag"></div>
        </div>
      </div>

      <div v-if="chose1">
        <div class="main">
          <div class="title" style="margin-bottom: 60px">
            <p>16级标准</p>
            <p class="titledes">严选专职课研老师，严控课程研发质量</p>
          </div>

          <div class="commonboxline">
            <div class="topbg">
              <div class="left">人才筛选</div>
              <div class="right">4项标准</div>
            </div>
            <div
              class="item"
              v-for="(item, index) in commonboxlinecontent"
              :key="index"
            >
              <img class="icon" :src="item.img" alt="logo" />
              <div class="info">
                <div class="top">{{ item.top }}</div>
                <div class="bottom">{{ item.bottom }}</div>
              </div>
            </div>
          </div>

          <div class="commonbox">
            <div class="topbg">
              <div class="left">人才考核</div>
              <div class="right">8大环节</div>
            </div>

            <div class="li">
              <div
                class="item"
                v-for="(item, index) in commonboxli"
                :key="index"
              >
                <p class="num">{{ index + 1 }}</p>
                <p class="des">{{ item }}</p>
              </div>
            </div>
          </div>

          <div class="commonboxline">
            <div class="topbg" style="width: 268px">
              <div class="left" style="width: 173px">人才培训及发展规划</div>
              <div class="right">4项标准</div>
            </div>

            <div class="item" v-for="(item, index) in planboxinfo" :key="index">
              <div class="planbox">{{ item }}</div>
            </div>
          </div>

          <div class="title">
            <p>“五库模式”</p>
            <p class="titledes">打造企业级课程研发体系师资基石</p>
          </div>

          <div class="storage">
            <div
              class="stobox"
              v-for="(item, index) in storagebox"
              :key="index"
            >
              <div class="nav">
                <img :src="item.icon" class="icon" alt="icon" />
                <p class="des">{{ item.des }}</p>
              </div>
              <p class="info">{{ item.info }}</p>
            </div>
          </div>

          <div class="title">
            <p>师资丰富</p>
            <p class="titledes">授课经验丰富的教学老师 带你乘风破浪</p>
          </div>

          <div class="teachercontent">
            <div
              class="item"
              v-for="(item, index) in teacherlist1"
              :key="index"
            >
              <img :src="item.imgurl" alt="taecherimg" class="img" />
              <p class="name">{{ item.name }}</p>
              <p class="des">{{ item.ubbtext }}</p>
            </div>
          </div>
          <div class="teachercontent">
            <div
              class="item"
              v-for="(item, index) in teacherlist2"
              :key="index"
            >
              <img :src="item.imgurl" alt="taecherimg" class="img" />
              <p class="name">{{ item.name }}</p>
              <p class="des">{{ item.ubbtext }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="chose2">
        <div class="main">
          <div class="title" style="margin-bottom: 60px">
            <p>优中择优</p>
            <p class="titledes">录取率小于3%，严格控制师资授课质量</p>
          </div>

          <div class="commonboxline">
            <div class="topbg">
              <div class="left">人才筛选</div>
              <div class="right">4项标准</div>
            </div>
            <div
              class="item"
              v-for="(item, index) in commonboxlinecontent2"
              :key="index"
            >
              <img class="icon" :src="item.img" alt="logo" />
              <div class="info">
                <div class="top">{{ item.top }}</div>
                <div class="bottom">{{ item.bottom }}</div>
              </div>
            </div>
          </div>

          <div class="commonbox">
            <div class="topbg">
              <div class="left">人才考核</div>
              <div class="right">8大环节</div>
            </div>

            <div class="li">
              <div
                class="item"
                v-for="(item, index) in commonboxli2"
                :key="index"
              >
                <p class="num">{{ index + 1 }}</p>
                <p class="des">{{ item }}</p>
              </div>
            </div>
          </div>

          <div class="commonboxline">
            <div class="topbg" style="width: 268px">
              <div class="left" style="width: 173px">人才培训及发展规划</div>
              <div class="right">4项标准</div>
            </div>

            <div
              class="item"
              v-for="(item, index) in planboxinfo2"
              :key="index"
            >
              <div class="planbox">{{ item }}</div>
            </div>
          </div>

          <div class="title" style="margin-bottom: 22px">
            <p>“四大创新”</p>
            <p class="titledes">教学团队深入透彻讲解技术重难点</p>
          </div>

          <div class="methodcard">
            <div class="topbtn">
              <div
                :class="chose1card ? 'btn btnchose' : 'btn'"
                @click="chosecard(1)"
              >
                标准化实施（授课）流程
              </div>
              <div
                :class="chose2card ? 'btn btnchose' : 'btn'"
                @click="chosecard(2)"
              >
                考试实战培养
              </div>
            </div>
            <div v-if="chose1card">
              <div class="card">
                <div class="nav">
                  <p class="num">1</p>
                  <p class="info">「分层次」教学体系</p>
                </div>

                <p class="navdes">
                  为了解决学生学习效能的根本问题，引入分层次学习体系
                </p>

                <div class="foruser">
                  <div
                    class="item"
                    v-for="(item, index) in foruserlist"
                    :key="index"
                  >
                    <div class="top">{{ item.top }}</div>
                    <p class="for">{{ item.for }}</p>
                    <p class="orgp">{{ item.orgp }}</p>
                    <p class="grayp">
                      {{ item.grayp }}
                    </p>
                  </div>
                </div>

                <div class="cline"></div>

                <div class="nav">
                  <p class="num" style="margin-right: 6px">2</p>
                  <p class="info">OPEN教学法</p>
                </div>
                <p class="navdes">
                  创办教学方法论，旨在用更短的时间讲明白一个知识点
                </p>

                <div class="teachme">
                  <div
                    class="box"
                    v-for="(item, index) in teachmelist"
                    :key="index"
                  >
                    <p class="top">{{ item.top }}</p>
                    <p class="bottom">{{ item.bottom }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="chose2card">
              <div class="card">
                <div class="nav">
                  <p class="num" style="margin-right: 6px">3</p>
                  <p class="info">沉浸式考试实战</p>
                </div>

                <p class="navdes">
                  还原学生进入考场后错综复杂的答题思维，真正了解考试
                  实际中的各种实际情况
                </p>

                <div class="infoli">
                  <div
                    class="item"
                    v-for="(item, index) in infolist"
                    :key="index"
                    style="margin-top: 10px"
                  >
                    <p class="num">{{ index + 1 }}</p>
                    <p class="des">{{ item }}</p>
                  </div>
                </div>

                <div class="cline"></div>

                <div class="nav">
                  <p class="num" style="margin-right: 6px">4</p>
                  <p class="info">情景式授课</p>
                </div>
                <p class="navdes">
                  场景式教学集风趣、知识、故事场景于一体，可大大提高
                  学生对知识的理解和吸收
                </p>

                <div class="lessoncen">
                  <div
                    class="box"
                    v-for="(item, index) in lessoncenlist"
                    :key="index"
                  >
                    <div class="nav">
                      <p class="num">{{ index + 1 }}</p>
                      {{ item.nav }}
                    </div>
                    <p class="bottom">{{ item.bottom }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="title">
            <p>师资丰富</p>
            <p class="titledes">授课经验丰富的教学老师 带你乘风破浪</p>
          </div>
          <div class="teachercontent">
            <div
              class="item"
              v-for="(item, index) in teacherlist1"
              :key="index"
            >
              <img :src="item.imgurl" alt="taecherimg" class="img" />
              <p class="name">{{ item.name }}</p>
              <p class="des">{{ item.ubbtext }}</p>
            </div>
          </div>
          <div class="teachercontent">
            <div
              class="item"
              v-for="(item, index) in teacherlist2"
              :key="index"
            >
              <img :src="item.imgurl" alt="taecherimg" class="img" />
              <p class="name">{{ item.name }}</p>
              <p class="des">{{ item.ubbtext }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img
      class="bottomimg"
      src="https://oss.boxuanedu.cn/boxuanedu-m/teacher/partner"
      alt="bottomimg"
    />

    <myfooter></myfooter>
  </div>
</template>

<script>
import theheader from "./headfooter/head.vue";
import myfooter from "./headfooter/foot.vue";
import { Swipe, SwipeItem } from "vant";
import axios from "axios";
export default {
  components: {
    theheader,
    myfooter,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      chose1: 1,
      chose2: 0,
      chose1card: 1,
      chose2card: 0,
      teacherlist1: "",
      teacherlist2: "",
      commonboxlinecontent: [
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon1",
          top: "标准化讲师",
          bottom: "人才画像",
        },
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon2",
          top: "多维度面试",
          bottom: "五面",
        },
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon3",
          top: "研究院小组",
          bottom: "诊断测评",
        },
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon4",
          top: "全链路面试",
          bottom: "流程监控",
        },
      ],
      commonboxlinecontent2: [
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon1",
          top: "标准化讲师",
          bottom: "人才画像",
        },
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon2",
          top: "初试",
          bottom: "授课深度",
        },
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon3",
          top: "复试",
          bottom: "授课质量高",
        },
        {
          img: "https://oss.boxuanedu.cn/boxuanedu-m/teacher/icon/icon4",
          top: "终试",
          bottom: "教师评测",
        },
      ],

      commonboxli: [
        "课研人员素质考核",
        "课研人员素质考核",
        "课程设计考核",
        "课堂试炼考核",
        "大纲设计考核",
        "产品全方位审评",
        "讲义撰写考核",
        "考核答辩",
      ],

      commonboxli2: [
        "定制个性化方案",
        "课程产出物考核",
        "教育心理考核",
        "课堂试讲考核",
        "讲师素质考核",
        "视频录制考核",
        "教学方法考核",
        "正式授课答辩",
      ],

      planboxinfo: [
        "平台、组件技术开源历练",
        "知识点私享会",
        "大牛授课沙龙",
        "团队对对碰思维共享",
      ],
      planboxinfo2: [
        "每日授课 学员满意度打分",
        "阶段课程实施 评审组审核",
        "博轩培训院 多维培养计划",
        "讲师专属 晋升通道",
      ],
      storagebox: [
        {
          icon: "	https://oss.boxuanedu.cn/boxuanedu/lesson/lessonicon/tag1",
          des: "信息库",
          info: "应用市场调研+大数据分析获取考点大纲方向",
        },
        {
          icon: "	https://oss.boxuanedu.cn/boxuanedu/lesson/lessonicon/tag2",
          des: "课题研究库",
          info: "提出前沿热门课题，完成深入原理剖析+考点攻坚保障课程前瞻性",
        },
        {
          icon: "	https://oss.boxuanedu.cn/boxuanedu/lesson/lessonicon/tag3",
          des: "教研人才库",
          info: "来自粉笔、华图等百人大牛团，每年耗资亿元研发行业标杆优质课程",
        },
        {
          icon: "	https://oss.boxuanedu.cn/boxuanedu/lesson/lessonicon/tag4",
          des: "解决方案库",
          info: "应用市场调研+大数据分析获取考点大纲方向",
        },
        {
          icon: "	https://oss.boxuanedu.cn/boxuanedu/lesson/lessonicon/tag5",
          des: "项目库",
          info: "基于出题人思维联合大牛顾问团教研“大厂级”深度项目",
        },
      ],
      foruserlist: [
        {
          top: "实用篇",
          for: "针对学员",
          orgp: "80%常见思维",
          grayp: "抽取最常用必备知识，降低学习难度，让学生学得会用得上",
        },

        {
          top: "高级篇",
          for: "针对学员",
          orgp: "20%复杂思维",
          grayp: "加深学生对大纲、技巧用法使用能力，应对高难度题型",
        },

        {
          top: "实用篇",
          for: "提升个人",
          orgp: "面试能力",
          grayp: "经典面试流程、答题要点、面试技巧，让学生一战成公",
        },
      ],
      teachmelist: [
        {
          top: "Objective 目标",
          bottom: "具体要交付给学生的能力学生可以用来解决具体的问题",
        },
        {
          top: "Path 路径",
          bottom: "基于学生既有知识储备设计学习线路",
        },
        {
          top: "Experience 体验",
          bottom: "按照路径顺序授课，主线清晰保证学习体验",
        },
        {
          top: "Note 落地结论",
          bottom: "交付给学生经过提炼的知识干货降低复习难度，提高学习效率",
        },
      ],
      infolist: [
        "采用故事牵引教学法沉浸学习，乐在其中",
        "46个专业化、科学化的任务设计,带你一路“闯关打怪”",
        "根据人群的特点在趣味学习中摸透考场规则",
        "多维度能力提升训练，对标用人企业能力模型",
        "19个工作场景贯穿设计体制内生活节奏",
        "专业技能硬实力提升，情商软实力的培养",
      ],
      lessoncenlist: [
        {
          nav: "感知阶段",
          bottom:
            "培养兴趣：引入知识点情景，形成表象，幽默故事，解读晦涩难懂概念。",
        },
        {
          nav: "理解阶段",
          bottom:
            "考点双结合：深入考试场景，考点与大纲结合，更好理解出题人出题规律。让考点不再陌生，不知不觉掌握每个知识点。",
        },
        {
          nav: "深化阶段",
          bottom:
            "深入知识延展：深入知识点的其他使用或底层原理，保障知识点牢固掌握。",
        },
      ],
    };
  },
  methods: {
    chosebtnbox(i) {
      if (i == 0) {
        this.chose1 = 1;
        this.chose2 = 0;
      } else {
        this.chose2 = 1;
        this.chose1 = 0;
      }
    },
    chosecard(i) {
      if (i == 1) {
        this.chose1card = 1;
        this.chose2card = 0;
      } else {
        this.chose2card = 1;
        this.chose1card = 0;
      }
    },
    /* 获取老师数据 */
    getdata() {
      const url = "https://das.boxuanedu.cn/dd/rest/teacherInfo/export";
      axios
        .post(url)
        .then((response) => {
          const list = response.data.data.list;
          this.teacherlist1 = list.splice(0, list.length / 2 - 1);
          this.teacherlist2 = list.splice(list.length / 2 - 1, list.length - 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    } /* 整体滚动动画 */,
    handlescroll() {
      const realscolltop = this.$parent.$el.childNodes[0].scrollTop;
      const clientHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      // 设定开发的基准视口高度
      const scollstand = 667;
      const scolldeviation = clientHeight - scollstand;
      console.log(realscolltop, scolldeviation);
      if (realscolltop >= 200 - scolldeviation) {
        const dom = document.querySelectorAll(".commonboxline")[0];
        dom.className = "commonboxline animationcommonboxline";
      }
      if (realscolltop >= 400 - scolldeviation) {
        const dom = document.querySelectorAll(".commonbox")[0];
        dom.className = "commonbox animationcommonbox";
      }
      if (realscolltop >= 600 - scolldeviation) {
        const dom = document.querySelectorAll(".commonboxline")[1];
        dom.className = "commonboxline animationcommonboxline";
      }
      if (realscolltop >= 1050 - scolldeviation) {
        const dom = document.querySelectorAll(".storage")[0];
        dom.className = "storage animationstorage";
        const domchild = document.querySelectorAll(
          ".storage .stobox .nav .icon"
        );
        let delay = 500;
        for (const item of domchild) {
          setTimeout(() => {
            item.className = "icon animationstorageicon";
          }, delay);
          delay += 100;
        }
      }
    },
  },
  mounted() {
    this.getdata();
    this.$parent.$el.childNodes[0].scrollTop = 0;
    window.addEventListener("scroll", this.handlescroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handlescroll, true);
  },
};
</script>

<style lang="less" scoped>
.teacher {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pagedes {
    width: 354px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 28px;
    opacity: 0;
    animation: renderpagedes 1s linear forwards;
    .pagep {
      width: 162px;
      font-size: 18px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #090909;
      line-height: 28px;
    }
    .pagep::after {
      content: "";
      display: block;
      width: 52px;
      height: 5px;
      background: #0f67ec;
      margin-top: 16px;
      border-radius: 16px;
    }
    .desimg {
      width: 167px;
      height: 96px;
      object-fit: contain;
      margin-bottom: 18px;
    }
    .desinfo {
      width: 354px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #090909;
      line-height: 24px;
      margin-bottom: 22px;
    }
  }

  .btnbox {
    width: 326px;
    display: flex;
    justify-content: space-between;
    animation: renderbtnbox 1s linear forwards 1s;
    margin-bottom: 27px;
    opacity: 0;
    .btn {
      width: 157px;
      height: 69px;
      background: #eeeeee;
      border-radius: 8px;
      padding-left: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .num {
        font-size: 16px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #404040;
      }
      .des {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #404040;
      }
      .btnflag {
        width: 0;
        height: 0;
        border: 10px solid;
        border-color: #0f67ec transparent transparent transparent;
        position: absolute;
        top: 69px;
        left: 70px;
      }
    }

    .btnchose {
      background: #0f67ec;
      .num,
      .des {
        color: #fff;
      }
    }
  }

  .main {
    width: 100vw;
    background: #fafafc;
    padding-top: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .commonbox {
      width: 330px;
      height: 201px;
      background: #fff;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      position: relative;
      margin-bottom: 47px;
      opacity: 0;

      .topbg {
        position: absolute;
        width: 200px;
        height: 40px;
        background: linear-gradient(94deg, #0f67ec 0%, #1e92f8 100%);
        border-radius: 4px 4px 4px 4px;
        top: -20px;
        display: flex;
        .left {
          width: 100px;
          height: 40px;
          background: linear-gradient(360deg, #ffffff 0%, #f3f5f8 100%);
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.15);
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff5a13;
        }
        .right {
          width: 100px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .li {
        width: 280px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          display: flex;
          margin-bottom: 7px;
          .num {
            width: 8px;
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #0f67ec;
            margin-right: 6px;
          }
          .des {
            width: 120px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
    .commonboxline {
      width: 330px;
      height: 200px;
      padding-top: 30px;
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      position: relative;
      margin-bottom: 47px;
      opacity: 0;
      .topbg {
        position: absolute;
        width: 200px;
        height: 40px;
        background: linear-gradient(94deg, #0f67ec 0%, #1e92f8 100%);
        border-radius: 4px 4px 4px 4px;
        top: -20px;
        display: flex;
        .left {
          width: 100px;
          height: 40px;
          background: linear-gradient(360deg, #ffffff 0%, #f3f5f8 100%);
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.15);
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 1px solid #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff5a13;
        }
        .right {
          width: 100px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .item {
        width: 146px;
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          width: 28px;
          height: 22px;
          object-fit: contain;
          margin-right: 11px;
        }
        .info {
          .top {
            font-size: 15px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #303030;
          }
          .bottom {
            font-size: 15px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
          }
        }
        .planbox {
          width: 98px;
          height: 40px;
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #000000;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .item:nth-child(2n-1) {
        border-left: 1px solid #e5e5e5;
      }
      .item:nth-child(3) {
        border-left: 1px solid #e5e5e5;
      }
      .item:nth-child(2) {
        border-bottom: 1px solid #e5e5e5;
      }
      .item:nth-child(3) {
        border-bottom: 1px solid #e5e5e5;
      }
    }
    .storage {
      width: 330px;
      height: 290px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      padding: 11px 9px 13px 16px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 30px;
      opacity: 0;
      .stobox {
        width: 141px;
        .nav {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .icon {
            width: 28px;
            height: 28px;
            object-fit: contain;
            margin-right: 7px;
          }
          .des {
            font-size: 15px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #141414;
          }
        }
        .info {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
          padding-right: 20px;
        }
      }
    }

    .methodcard {
      display: flex;
      flex-direction: column;
      width: 100vw;

      align-items: center;
      .topbtn {
        width: 303px;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 147px;
          height: 26px;
          background: #e7e7e7;
          border-radius: 4px 4px 0px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #6e6e6e;
        }
        .btnchose {
          background: linear-gradient(94deg, #0f67ec 0%, #1e92f8 100%);
          color: #ffffff;
        }
      }
      .card {
        width: 330px;
        background: #ffffff;
        padding: 12px 14px 19px 9px;
        .nav {
          display: flex;
          align-items: baseline;
          .num {
            width: 8px;
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #0f67ec;
            line-height: 0px;
          }
          .info {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #303030;
          }
        }

        .navdes {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
        }

        .foruser {
          width: 303px;
          display: flex;
          justify-content: space-between;
          margin-top: 17px;
          .item {
            width: 91px;
            .top {
              width: 52px;
              height: 23px;
              background: linear-gradient(94deg, #0f67ec 0%, #1e92f8 100%);
              border-radius: 4px 4px 4px 4px;
              font-size: 11px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #f6f6f6;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .for {
              margin-top: 6px;
              margin-bottom: 4px;
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #141414;
            }
            .orgp {
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff5a13;
            }
            .grayp {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #aaaaaa;
            }
          }
        }

        .cline {
          width: 311px;
          height: 0px;
          opacity: 1;
          border: 1px solid #e4e4e4;
          margin-top: 22px;
          margin-bottom: 22px;
        }

        .teachme {
          width: 300px;
          display: flex;
          flex-wrap: wrap;
          margin-top: 13px;
          .box {
            width: 150px;
            height: 96px;
            padding: 9px 8px 4px 13px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .top {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #303030;
            }
            .bottom {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #aaaaaa;
            }
          }
          .box:nth-child(2) {
            background: #f5f5f5;
          }
          .box:nth-child(3) {
            background: #f5f5f5;
          }
        }

        .infoli {
          display: flex;
          flex-wrap: wrap;
          width: 311px;
          .item {
            display: flex;
            margin-bottom: 7px;
            .num {
              width: 7px;
              height: 17px;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ff5a13;
              margin-right: 6px;
            }
            .des {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #7b7b7b;
            }
          }
        }

        .lessoncen {
          margin-top: 13px;
          width: 313px;
          .box {
            width: 100%;
            .nav {
              display: flex;
              font-size: 14px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #ff5a13;
              align-items: baseline;
              .num {
                margin-right: 6px;
                font-size: 14px;
                font-family: PingFang SC-Bold, PingFang SC;
                font-weight: bold;
                color: #ff5a13;
              }
            }
            .bottom {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #7b7b7b;
              margin-bottom: 9px;
            }
          }
        }
      }
    }

    .teachercontent {
      width: 365px;
      height: 183px;
      display: flex;
      overflow-x: scroll;

      .item {
        margin-right: 9px;
        .img {
          width: 110px;
          height: 110px;
          border-radius: 4px;
          object-fit: contain;
        }
        .name {
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #0a0a0a;
          margin-top: 7px;
        }
        .des {
          width: 110px;
          height: 14px;
          font-size: 10px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #0a0a0a;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
}
.topimg {
  width: 375px;
  height: 207px;
  object-fit: contain;
  margin-top: 50px;
}

.tline {
  width: 100vw;
  height: 11px;
  background: #fafafc;
  margin-bottom: 22px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #090909;
  margin-bottom: 35px;
  position: relative;
  left: -20px;
}

.titledes {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7a7a7a;
  margin-left: 7px;
}

.title::before {
  content: "";
  display: block;
  width: 4px;
  height: 20px;
  background: #ff5a13;
  border-radius: 8px;
  margin-right: 7px;
}

.bottomimg {
  width: 375px;
  height: 150px;
  object-fit: contain;
  position: relative;
  top: 25px;
}

/* 动画 */
@keyframes renderpagedes {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes renderbtnbox {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rendercommonboxline {
  0% {
    opacity: 0;
    left: -100px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}
.animationcommonboxline {
  animation: rendercommonboxline 1s linear forwards;
}

@keyframes rendercommonbox {
  0% {
    opacity: 0;
    right: -100px;
  }
  100% {
    opacity: 1;
    right: 0px;
  }
}

.animationcommonbox {
  animation: rendercommonbox 1s linear forwards;
}

@keyframes renderstorage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animationstorage {
  animation: renderstorage 1s linear forwards;
}

@keyframes renderstorageicon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.animationstorageicon {
  animation: renderstorageicon 1s linear forwards;
}
</style>

<template>
  <div class="recruit">
    <theheader></theheader>
    <img
      class="bg1"
      src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/m_01.png"
      alt="bg1"
    />

    <div class="tagbox">
      <div class="item" style="animation-delay: 0">
        <img
          src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag1.png"
          alt="tag"
          class="tag"
          style="animation-delay: 2s"
        />
        <div class="info">
          <p class="title">博轩C位</p>
          <p class="des">
            在博轩，教研是公司的核心职位，备受学子尊敬，也是博轩教育文化传承的灵魂。
          </p>
        </div>
      </div>

      <div class="item" style="animation-delay: 0.5s">
        <img
          src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag2.png"
          alt="tag"
          class="tag"
          style="animation-delay: 2.2s"
        />
        <div class="info">
          <p class="title">待遇C位</p>
          <p class="des">
            在博轩，我们把更多精力和财力用在了教研上，教研人才待遇完胜同行业。
          </p>
        </div>
      </div>

      <div class="item" style="animation-delay: 1s">
        <img
          src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag3.png"
          alt="tag"
          class="tag"
          style="animation-delay: 2.4s"
        />
        <div class="info">
          <p class="title">发展C位</p>
          <p class="des">
            在博轩，我们拥有完善的教研人才培养机制和晋级制度，平台广阔可肆意施展抱负。
          </p>
        </div>
      </div>
    </div>

    <div class="jobox">
      <div class="commontitle">人才招聘榜</div>
      <div class="jobmeun">
        <div
          class="item"
          v-for="(item, index) in jobmenu"
          :key="index"
          @click="changecontent(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="jobcontent">
        <div class="jobtitle">
          <img
            src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/icon1.png"
            alt="icon"
            class="icon"
          />
          <p>工作内容</p>
        </div>

        <div
          class="jobinfo"
          v-for="(item, index) in jobinfoitem.work"
          :key="index"
        >
          <p class="taghead">{{ item.tag }}</p>
          <p class="tagdes">{{ item.tagdes }}</p>
        </div>

        <div class="jobtitle">
          <img
            src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/icon2.png"
            alt="icon"
            class="icon"
          />
          <p>薪资福利</p>
        </div>
        <div
          class="jobinfo"
          v-for="(item, index) in jobinfoitem.pay"
          :key="'c' + index"
        >
          <p class="taghead">{{ item.tag }}</p>
          <p class="tagdes">{{ item.tagdes }}</p>
        </div>

        <div class="jobtitle">
          <img
            src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/icon3.png"
            alt="icon"
            class="icon"
          />
          <p>岗位职责</p>
        </div>
        <div
          class="jobinfo"
          v-for="(item, index) in jobinfoitem.need"
          :key="'d' + index"
        >
          <p class="taghead">{{ item.tag }}</p>
          <p class="tagdes">{{ item.tagdes }}</p>
        </div>
      </div>
    </div>

    <div class="platform">
      <div class="commontitle">博轩平台介绍</div>
      <div class="infocontent">
        <div class="imgbox bg1">
          <p class="title">专注于公务员招录考试考前培训</p>
          <p class="des">
            通过十多年的不懈努力，博轩团队“专业高效、工作认真、分工细致、教研精准、授课高质、服务用心”，用高品质的培训帮助数千学员成功考取公务员
          </p>
        </div>
        <div class="imgbox bg2">
          <p class="title">全方位服务</p>
          <p class="des">
            定位服务型学校，授课老师、课程顾问、带班老师等以“多对一”的方式来服务学员。每天关注学员“身体健康、情绪波动、出勤作息”，定期分析学员成绩变化并进行针对性调整，随时调动班级的学习氛围、协调同学关系。
          </p>
        </div>

        <div class="centerp">
          行业领先的优秀平台
          <p>任你职场驰骋</p>
        </div>

        <div class="tagbox">
          <div class="tagcontent">
            <div class="tagitem">
              <img
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag4.png"
                alt="tag"
                class="tag"
                style="animation-delay: 0.2s"
              />
              <div class="num">15+</div>
              <p class="des">长期合租品牌</p>
            </div>

            <div class="tagitem">
              <img
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag5.png"
                alt="tag"
                class="tag"
                style="animation-delay: 0.4s"
              />
              <div class="num">12亿+</div>
              <p class="des">覆盖用户数量</p>
            </div>
          </div>

          <div class="tagcontent">
            <div class="tagitem">
              <img
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag6.png"
                alt="tag"
                class="tag"
                style="animation-delay: 0.6s"
              />
              <div class="num">3.2亿+</div>
              <p class="des">年均投放规模</p>
            </div>

            <div class="tagitem">
              <img
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag7.png"
                alt="tag"
                class="tag"
                style="animation-delay: 0.8s"
              />
              <div class="num">5000+</div>
              <p class="des">平台露出广告</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="belief">
      <div class="commontitle">
        企业理念
        <p class="more">创新教育 成就每一个人</p>
      </div>
      <div class="beliefbox">
        <div class="content">
          <div class="item">
            <img
              class="tag"
              src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag8.png"
              alt="tag"
            />
            <p class="title">企业宗旨</p>
            <p class="des">
              终坚持教育本质；始终坚持以学员为核心；始终坚持好老师、好产品；始终专注于教学口碑。
            </p>
          </div>

          <div class="item">
            <img
              class="tag"
              src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag9.png"
              alt="tag"
            />
            <p class="title">企业使命</p>
            <p class="des">
              让每个人都能公平和便利地获取优质教育服务，并实现可持续成长。让每位学员都能得到更好的教育服务。
            </p>
          </div>
        </div>

        <div class="content">
          <div class="item">
            <img
              class="tag"
              src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag10.png"
              alt="tag"
            />
            <p class="title">企业愿景</p>
            <p class="des">
              打造全球顶尖的人才公司，创办一所国际知名大学，成为令人尊敬的企业，被视为教育行业的标杆。
            </p>
          </div>

          <div class="item">
            <img
              class="tag"
              src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/tag11.png"
              alt="tag"
            />
            <p class="title">企业价值观</p>
            <p class="des">
              坚持做经得住时间检验的事情、极致敢为、坦诚开放、知行合一、拥抱变化、始终创业。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="menber">
      <div class="commontitle">员工在职感受</div>
      <van-swipe
        :autoplay="10000"
        indicator-color="black"
        :show-indicators="false"
        class="myswiper"
      >
        <van-swipe-item class="myitem">
          <div class="item">
            <div class="title">
              <img
                class="icon"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/icon4.png"
                alt="icon"
              />
              销售总监
            </div>
            <p class="des">
              公司氛围好，都很年轻有活力，老板nice。上班可以自己带饭，团队都是90后95后，大家都是年轻人，氛围轻松，平时办公室也是热热闹闹，工作之余大家也经常组织活动。
            </p>
            <div class="people">
              <img
                class="img"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/p1.png"
                alt="per"
              />
              <div class="info">
                <p class="top">老代</p>
                <p class="des">在职5年</p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="title">
              <img
                class="icon"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/icon4.png"
                alt="icon"
              />
              公考讲师
            </div>
            <p class="des">
              都是年轻人，沟通没有障碍，领导和管理层很开朗，没有森严的上下级关系，收入也很稳定，下午餐团建聚餐经常举办，都是一群做事的人
            </p>
            <div class="people">
              <img
                class="img"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/p2.png"
                alt="per"
              />
              <div class="info">
                <p class="top">江枫</p>
                <p class="des">在职4年</p>
              </div>
            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item class="myitem">
          <div class="item">
            <div class="title">
              <img
                class="icon"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/icon4.png"
                alt="icon"
              />
              售后负责人
            </div>
            <p class="des">
              公司一切以学员利益为根本，用心教学。为学员服务如同为自己服务一样，耐心的解答学员任何生活上及学习上的问题。教学水平高，服务质量好！
            </p>
            <div class="people">
              <img
                class="img"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/p3.png"
                alt="per"
              />
              <div class="info">
                <p class="top">小鱼</p>
                <p class="des">在职3年</p>
              </div>
            </div>
          </div>

          <div class="item">
            <div class="title">
              <img
                class="icon"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/icon4.png"
                alt="icon"
              />
              研发主管
            </div>
            <p class="des">
              朝九晚六，平台大，正规化，有发展空间，业内大牛带队，技术成长不是问题。
            </p>
            <div class="people">
              <img
                class="img"
                src="https://oss.boxuanedu.cn/boxuanedu-m/recruit/p4.png"
                alt="per"
              />
              <div class="info">
                <p class="top">勇哥</p>
                <p class="des">在职3年</p>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="connect">
      <p>联系人：王老师</p>
      <p style="margin-right: 0">咨询电话：17798207841</p>
      <p style="margin: 0">简历投递： 1023424596@qq.com</p>
    </div>

    <myfooter></myfooter>
  </div>
</template>

<script>
import theheader from "../headfooter/head.vue";
import myfooter from "../headfooter/foot.vue";
import { recruitinfof, jobinfo } from "../../tempdata/recruit/recruit";
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    theheader,
    myfooter,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      recruitinfof,
      jobmenu: ["课程开发主管", "行测申论高级讲师", "课程讲师", "班主任"],
      jobinfoitem: "",
      lastindex: "st",
    };
  },
  mounted() {
    this.jobinfoitem = jobinfo[0];
    this.changecontent(0);
    this.$parent.$el.childNodes[0].scrollTop = 0;

    window.addEventListener("scroll", this.handlescroll, true);
  },
  methods: {
    changecontent(i) {
      this.jobinfoitem = jobinfo[i];
      this.rendermeun(i);
      this.lastindex = i;
    },
    rendermeun(i) {
      const thedom = document.querySelector(".jobmeun").childNodes;
      thedom[i].className = "item chose";
      if (this.lastindex != "st" && this.lastindex != i) {
        thedom[this.lastindex].className = "item";
      }
    },
    /* 整体滚动动画 */
    handlescroll() {
      const dom = this.$refs.box;
      const realscolltop = this.$parent.$el.childNodes[0].scrollTop;
      if (realscolltop >= 1155) {
        const thedom = document.querySelectorAll(".imgbox");
        thedom[0].className = "imgbox imgboxreder bg1";
        thedom[1].className = "imgbox imgboxreder bg2";
      }
      if (realscolltop >= 1590) {
        const thedom = document.querySelectorAll(".tagitem ");
        for (const item of thedom) {
          console.log(item.childNodes[0]);
          item.childNodes[0].className = "tag platformiconrender";
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handlescroll, true);
  },
};
</script>

<style lang="less" scoped>
.recruit {
  width: 100vw;
  min-height: 100vh;
  background: #f5f5f5;
  .bg1 {
    width: 100%;
    margin-top: 48px;
  }

  .tagbox {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 22px;
    position: relative;
    top: -20px;
    .item {
      width: 100%;
      height: 80px;
      padding: 12px;
      display: flex;
      box-shadow: 0px 0px 8px 1px rgba(15, 103, 236, 0.08);
      border-radius: 8px;
      align-items: center;
      margin-bottom: 12px;
      position: relative;
      opacity: 0;
      animation: rendertoptagbox 0.8s linear forwards;
      .tag {
        width: 52px;
        height: 52px;
        margin-right: 12px;

        animation: rendertopicon 1s linear 2s;
      }
      .info {
        .title {
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #131313;
          margin-bottom: 3px;
        }
        .des {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #131313;
        }
      }
    }
  }

  .jobox {
    padding: 0 11px;
    .jobmeun {
      display: flex;
      flex-wrap: wrap;
      padding-top: 14px;
      padding-left: 19px;
      .item {
        width: 148px;
        height: 32px;
        background: #ebebeb;
        border-radius: 4px;
        font-size: 13px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5a5a5a;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 19px;
        margin-bottom: 13px;
        transition: all 0.2s linear;
      }
      .chose {
        background: #0f67ec;
        color: #fff;
      }
    }

    .jobcontent {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(15, 103, 236, 0.09);
      border-radius: 8px;
      padding: 8px 10px;
      .jobtitle {
        display: flex;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #0f67ec;
        align-items: center;
        margin-bottom: 18px;
        .icon {
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }
      .jobinfo {
        margin-left: 7px;
        margin-bottom: 20px;
        .taghead {
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #141414;
          margin-bottom: 4px;
          position: relative;
          left: -8px;
        }
        .tagdes {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #141414;
        }
      }
    }
  }

  .platform {
    padding: 0 11px;
    margin-top: 22px;
    .infocontent {
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 17px;
      .imgbox {
        width: 331px;
        height: 118px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        opacity: 0;
        .title {
          font-size: 16px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 3px;
        }
        .des {
          width: 284px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #f6f6f6;
          text-align: center;
        }
      }
      .bg1 {
        background: url("https://oss.boxuanedu.cn/boxuanedu-m/recruit/imgbox1.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .bg2 {
        background: url("https://oss.boxuanedu.cn/boxuanedu-m/recruit/imgbox2.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
      .centerp {
        display: flex;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        p {
          color: #0f67ec;
          margin-left: 4px;
        }
      }
      .centerp::before,
      .centerp::after {
        content: "";
        width: 32px;
        height: 0px;
        opacity: 1;
        border: 1px solid #0e0e0e;
        margin: 0 2px;
      }

      .tagbox {
        width: 245px;
        text-align: center;
        padding-top: 10px;
        position: relative;
        left: -5px;
        top: 0px;
        .tagcontent {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          .tagitem {
            .tag {
              width: 60px;
              height: 60px;
            }
            .num {
              font-size: 16px;
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #141414;
              margin-bottom: 2px;
            }
            .des {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #141414;
            }
          }
        }
      }
    }
  }

  .belief {
    padding: 11px;
    margin-top: 22px;
    background: #fff;
    .beliefbox {
      margin-top: 19px;
      .content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .item {
          width: 170px;
          height: 196px;
          background: #ffffff;
          box-shadow: 0px 0px 6px 1px rgba(15, 103, 236, 0.11);
          border-radius: 8px 8px 8px 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .tag {
            width: 46px;
            height: 46px;
            margin-bottom: 15px;
          }
          .title {
            font-size: 14px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #0f67ec;
            margin-bottom: 6px;
          }
          .des {
            width: 146px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #223341;
          }
        }
      }
    }
  }

  .menber {
    padding: 11px;
    margin-top: 12px;
    background: #fff;
    .myswiper {
      padding: 10px;
      margin-top: 20px;
      .myitem {
        display: flex;
      }
    }
    .item {
      width: 164px;
      height: 198px;
      margin-right: 6px;
      background: #ffffff;
      box-shadow: 0px 5px 10px 1px rgba(15, 103, 236, 0.1);
      border-radius: 8px 8px 8px 8px;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #141414;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        display: flex;
        margin-bottom: 11px;
        .icon {
          width: 19px;
          object-fit: contain;
          margin-right: 3px;
        }
      }
      .des {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #4e4e4e;
        line-height: 16px;
      }
      .people {
        display: flex;
        margin-top: 10px;
        .img {
          width: 25px;
          object-fit: contain;
          margin-right: 9px;
          border-radius: 50%;
        }
        .info {
          position: relative;
          top: 2px;
          .top {
            font-size: 10px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #0f67ec;
          }
          .des {
            font-size: 10px;
            font-family: PingFang SC-Light, PingFang SC;
            font-weight: 300;
            color: #707070;
          }
        }
      }
    }
  }

  .connect {
    background: #fff;
    padding: 24px;
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #383838;
    flex-wrap: wrap;
    p {
      margin-right: 40px;
      margin-bottom: 10px;
    }
  }
}

/**/
.commontitle {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #141414;
  .more {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(34, 51, 65, 0.5);
    position: relative;
    top: -14px;
    left: 224px;
  }
}
.commontitle::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 17px;
  background: #0f67ec;
  border-radius: 2px;
  margin-right: 6px;
  position: relative;
  top: 2px;
}

/**/
@keyframes rendertoptagbox {
  0% {
    opacity: 0;
    right: -100px;
  }
  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes rendertopicon {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.imgboxreder {
  animation: renderimgbox 1s linear forwards;
}

@keyframes renderimgbox {
  0% {
    top: 100px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.platformiconrender {
  animation: renderplatformicon 1s linear forwards;
}
@keyframes renderplatformicon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
